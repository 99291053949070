var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('trac-back-button',[_c('div',{staticClass:"mt-x"},[_vm._v("Sales")])]),_c('h1',{staticClass:"mt-3 font-medium"},[_vm._v("Download Report")]),_c('div',{staticClass:"bg-white rounded-md shadow-md pt-2 pb-8 px-5 mt-6 w-screen md:w-full"},[_c('div',{staticClass:"mt-10 mx-5 overflow-scroll pb-4"},[_c('div',{staticClass:"flex md:grid grid-cols-5"},[_c('a',{staticClass:"text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b",class:_vm.currentTab === 'products'
              ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
              : '',style:({ 'background-color': _vm.currentTab === 'products' ? '#253B950A' : 'transparent' }),on:{"click":function($event){_vm.currentTab = 'products'}}},[_vm._v("Sales By Products")]),_c('a',{staticClass:"text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b",class:_vm.currentTab === 'sales'
              ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
              : '',style:({ 'background-color': _vm.currentTab === 'sales' ? '#253B950A' : 'transparent' }),on:{"click":function($event){_vm.currentTab = 'sales'}}},[_vm._v("Sales Summary")]),_c('a',{staticClass:"text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b",class:_vm.currentTab === 'customers'
              ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
              : '',style:({ 'background-color': _vm.currentTab === 'customers' ? '#253B950A' : 'transparent' }),on:{"click":function($event){_vm.currentTab = 'customers'}}},[_vm._v("Sales By Customers")]),_c('a',{staticClass:"text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b",class:_vm.currentTab === 'employees'
              ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
              : '',style:({ 'background-color': _vm.currentTab === 'employees' ? '#253B950A' : 'transparent' }),on:{"click":function($event){_vm.currentTab = 'employees'}}},[_vm._v("Sales By Employees")]),_c('a',{staticClass:"text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b",class:_vm.currentTab === 'dateReport'
              ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
              : '',style:({ 'background-color': _vm.currentTab === 'dateReport' ? '#253B950A' : 'transparent' }),on:{"click":function($event){_vm.currentTab = 'dateReport'}}},[_vm._v("Sales By Date")])])]),_c('div',{staticClass:"mx-5"},[(_vm.currentTab === 'products')?_c('ProductsReport'):_vm._e(),(_vm.currentTab === 'sales')?_c('SalesReport'):_vm._e(),(_vm.currentTab === 'customers')?_c('CustomersReport'):_vm._e(),(_vm.currentTab === 'employees')?_c('EmployeesReport'):_vm._e(),(_vm.currentTab === 'dateReport')?_c('DateReport'):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }