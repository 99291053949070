<template>
  <div class="">
    <trac-loading class="" v-if="isLoading" />
    <div class="grid grid-cols-2 py-6 gap-5">
      <trac-multi-select-dropdown
        @input="updateSelectedStores"
        title="Select Store(s)"
        :items="stores"
        displayProp="name"
      ></trac-multi-select-dropdown>
      <div class=""></div>
      <div class="mt-2 flex relative items-center" v-click-outside="toggleDate">
        <trac-input
          :value="
            custom === '' ? '' : reportDateRange[0] + ' - ' + reportDateRange[1]
          "
          @click.native="open = !open"
          class="w-full cursor-pointer"
          placeholder="Date Range"
          style="background-color: #fff"
        ></trac-input>
        <div class="relative" @click.stop>
          <date-picker
            ref="datepicker"
            v-model="custom"
            @change="handleChange"
            @click.stop
            format="YYYY-MM-DD"
            :inline="true"
            v-if="open"
            class="absolute shadow-md right-0 mt-6 md:right-auto md:mt-4 z-20"
            range
          ></date-picker>
        </div>
      </div>
    </div>

    <div class="">
      <p class="text-sm text-primaryBlue font-semibold">Select fields</p>
      <div class="mt-3 ml-3 grid grid-cols-4">
        <div class="flex items-center gap-1 my-3">
          <input
            type="checkbox"
            v-model="allParameters"
            name=""
            id="all-criteria"
          />
          <label class="text-xs cursor-pointer" for="all-criteria">All</label>
        </div>
        <div
          class="flex items-center gap-1 my-3"
          v-for="(criterium, index) in criteria"
          :key="index"
        >
          <input
            type="checkbox"
            :value="criterium._id"
            v-model="selectedCriteria"
            name=""
            :id="'criterium' + index"
          />
          <label class="text-xs cursor-pointer" :for="'criterium' + index">{{
            criterium.name
          }}</label>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-3">
      <trac-button :disabled="!readyToDownload" @button-clicked="generateExcelSheet"
        >DOWNLOAD REPORT</trac-button
      >
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import DatePicker from "vue2-datepicker";
import ClickOutside from "vue-click-outside";
import { GET_USER_BUSINESS_ID } from '../../../browser-db-config/localStorage';

export default {
  components: { DatePicker },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      allParameters: false,
      isLoading: false,
      open: false,
      store: null,
      selectedEmployee: null,
      stores: null,
      users: null,
      custom: "",
      selectedCriteria: [],
      selectedEmployees: [],
      selectedStores: [],
      criteria: [
        // { _id: "date_of_sale", name: "Date of Sale" },
        // { _id: "product_name", name: "Product Name" },
        // { _id: "employee_name", name: "Employee Name" },
        // { _id: "sale_id", name: "Sale ID" },
        // { _id: "product_id", name: "Product ID" },
        // { _id: "employee_id", name: "Employee ID" },
        // { _id: "payment_method", name: "Payment Method" },


        // { _id: "quantity", name: "Quantity Sold" },
        // { _id: "discounts", name: "Discount" },
        // { _id: "total_cost_price", name: "Total Cost Price" },

        { _id: "total_number_of_sales", name: "Total Number of Sales" },
        { _id: "total_value_of_sales", name: "Total Value of Sales" },
        { _id: "total_cost_of_sales", name: "Total Cost of Sales" },
        { _id: "total_gross_profit", name: "Total Gross Profit" },
        { _id: "total_value_of_discount", name: "Total Value of Discount" },
      ],
    };
  },
  async created() {
    this.fetchAllStores();
    this.fetchAllUsers();
  },
  watch: {
    allParameters: {
      handler(flag) {
        if (flag) {
          this.selectedCriteria = this.criteria.map(cri => cri._id);
        } else {
          this.selectedCriteria = [];
        }
      }
    }
  },
  computed: {
    reportDateRange() {
      const dateRange = this.custom;
      return [
        new Date(dateRange[0]).toLocaleDateString(),
        new Date(dateRange[1]).toLocaleDateString(),
      ];
    },
    readyToDownload() {
      return this.selectedStores.length > 0 && this.custom.length > 0 && this.selectedCriteria.length > 0;
    },
  },
  methods: {
    handleChange(x, y) {
      if (x || y) {
        this.open = false;
      }
    },
    setStore(store) {
      this.store = store;
    },
    selectEmployee(employee) {
      this.selectedEmployee = employee;
    },
    toggleDate() {
      this.open = false;
    },
    updateSelectedEmployees(selectedEmployees) {
      this.selectedEmployees = selectedEmployees;
    },
    updateSelectedStores(selectedStores) {
      this.selectedStores = selectedStores;
    },
    async generateExcelSheet() {
      this.isLoading = true;

      const payload = {
        agentIDs: this.selectedEmployees.join(","),
        columns: this.selectedCriteria.join(","),
        startdatetime: this.custom === "" ? "" : new Date(this.custom[0]).toISOString().slice(0,-1),
        enddatetime: this.custom === "" ? "" : new Date(this.custom[1]).toISOString().slice(0,-1),
      };

      const url = `/v1/sales/reports/salesbydate?startdatetime=${payload.startdatetime}&enddatetime=${payload.enddatetime}&download=csv&columns=${payload.columns}&business_id=${GET_USER_BUSINESS_ID()}`

      const res = await this.$store.dispatch("DOWNLOAD_SPREADSHEET", url);
      if (res.status) {
        this.download(res.data);
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }

      this.isLoading = false;
    },
    download(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 10);
    },
    async fetchAllUsers() {
      const res = await this.$store.dispatch("FETCH_ALL_USERS");
      if (res.status) {
        this.users = (res.data || [])
          .sort((a, b) =>
            (a.first_name + " " + a.last_name).trim().toLowerCase() >
            (b.first_name + " " + b.last_name).trim().toLowerCase()
              ? 1
              : -1
          )
          .map((user) => {
            return {
              ...user,
              fullName: user.first_name + " " + user.last_name,
            };
          });
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.stores = res.data;
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
        // alert(res.message);
      }
    },
  },
};
</script>