<template>
  <div class="">
    <trac-back-button>
      <div class="mt-x">Sales</div>
    </trac-back-button>
    <h1 class="mt-3 font-medium">Download Report</h1>

    <div class="bg-white rounded-md shadow-md pt-2 pb-8 px-5 mt-6 w-screen md:w-full">

      <div class="mt-10 mx-5 overflow-scroll pb-4">
        <div class="flex md:grid grid-cols-5">
          <a @click="currentTab = 'products'" class="text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b"
            :style="{ 'background-color': currentTab === 'products' ? '#253B950A' : 'transparent' }" :class="
              currentTab === 'products'
                ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
                : ''
            ">Sales By Products</a>
          <a @click="currentTab = 'sales'" class="text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b"
            :style="{ 'background-color': currentTab === 'sales' ? '#253B950A' : 'transparent' }" :class="
              currentTab === 'sales'
                ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
                : ''
            ">Sales Summary</a>
          <a @click="currentTab = 'customers'" class="text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b"
            :style="{ 'background-color': currentTab === 'customers' ? '#253B950A' : 'transparent' }" :class="
              currentTab === 'customers'
                ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
                : ''
            ">Sales By Customers</a>
          <a @click="currentTab = 'employees'" class="text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b"
            :style="{ 'background-color': currentTab === 'employees' ? '#253B950A' : 'transparent' }" :class="
              currentTab === 'employees'
                ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
                : ''
            ">Sales By Employees</a>
          <a @click="currentTab = 'dateReport'" class="text-xs py-2 cursor-pointer px-2 whitespace-no-wrap inline w-full text-center border-b"
            :style="{ 'background-color': currentTab === 'dateReport' ? '#253B950A' : 'transparent' }" :class="
              currentTab === 'dateReport'
                ? 'bg-gradientLight font-semibold text-primaryBlue border-b border-primaryBlue'
                : ''
            ">Sales By Date</a>
        </div>
      </div>

      <div class="mx-5">
        <ProductsReport v-if="currentTab === 'products'" />
        <SalesReport v-if="currentTab === 'sales'" />
        <CustomersReport v-if="currentTab === 'customers'" />
        <EmployeesReport v-if="currentTab === 'employees'" />
        <DateReport v-if="currentTab === 'dateReport'" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsReport from "./ProductsReport.vue";
import CustomersReport from "./CustomersReport.vue";
import DateReport from "./DateReport.vue";
import SalesReport from "./SalesReport.vue";
import EmployeesReport from "./EmployeesReport.vue";

export default {
  data() {
    return {
      currentTab: "products",
    };
  },
  components: {
    ProductsReport,
    CustomersReport,
    DateReport,
    SalesReport,
    EmployeesReport,
  },
};
</script>